%cvx-icon {
    font-family: 'ChevronIcons' !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .font-cvx-icon, [class^="icon-"], [class*=" icon-"] {
    @extend %cvx-icon;
  }
  
  .icon-arrow-up:before {
    content: "\e5fd";
  }
  .icon-arrow-down:before {
    content: "\e5fe";
  }
  .icon-arrow-left:before {
    content: "\e5ff";
  }
  .icon-arrow-right:before {
    content: "\e600";
  }
  .icon-chevron-down:before {
    content: "\e601";
  }
  .icon-chevron-left:before {
    content: "\e602";
  }
  .icon-chevron-right:before {
    content: "\e603";
  }
  .icon-chevron-up:before {
    content: "\e604";
  }
  .icon-credit-card:before {
    content: "\e605";
  }
  .icon-file:before {
    content: "\e606";
  }
  .icon-globe:before {
    content: "\e607";
  }
  .icon-menu-hamburger:before {
    content: "\e608";
  }
  .icon-menu-left:before {
    content: "\e609";
  }
  .icon-menu-right:before {
    content: "\e60a";
  }
  .icon-new-window:before {
    content: "\e60b";
  }
  .icon-oil:before {
    content: "\e60c";
  }
  .icon-play-circle:before {
    content: "\e60d";
  }
  .icon-remove:before {
    content: "\e60e";
  }
  .icon-search:before {
    content: "\e60f";
  }
  .icon-share-alt:before {
    content: "\e610";
  }
  .icon-stats:before {
    content: "\e611";
  }
  .icon-tag:before {
    content: "\e612";
  }
  .icon-th-list:before {
    content: "\e613";
  }
  .icon-th:before {
    content: "\e614";
  }
  .icon-user:before {
    content: "\e615";
  }
  .icon-media:before {
    content: "\e616";
  }
  .icon-caret-right:before {
    content: "\e617";
  }
  .icon-globe-grid:before {
    content: "\e700";
  }
  .icon-caret-right-bold:before {
    content: "\e701";
  }
  .icon-comment-filled:before {
    content: "\e702";
  }
  .icon-comment-outline:before {
    content: "\e703";
  }
  .icon-expand-minus:before {
    content: "\e704";
  }
  .icon-expand-plus:before {
    content: "\e705";
  }
  .icon-like-filled:before {
    content: "\e706";
  }
  .icon-like-outline:before {
    content: "\e707";
  }
  .icon-play-triangle:before {
    content: "\e708";
  }
  .icon-triangle-down:before {
    content: "\e709";
  }
  .icon-triangle-up:before {
    content: "\e710";
  }
  .icon-chevron-bold-down:before {
    content: "\e900";
  }
  .icon-chevron-bold-left:before {
    content: "\e901";
  }
  .icon-chevron-bold-right:before {
    content: "\e902";
  }
  .icon-chevron-bold-up:before {
    content: "\e903";
  }
  .icon-archive-mail:before {
    content: "\e904";
  }
  .icon-calendar:before {
    content: "\e905";
  }
  .icon-chat:before {
    content: "\e906";
  }
  .icon-email:before {
    content: "\e907";
  }
  .icon-hotline:before {
    content: "\e908";
  }
  .icon-archive-news:before {
    content: "\e909";
  }
  .icon-not-on-target:before {
    content: "\e90a";
  }
  .icon-on-target:before {
    content: "\e90b";
  }
  .icon-some-gaps:before {
    content: "\e90c";
  }
  .icon-view-counts:before {
    content: "\e90d";
  }
  