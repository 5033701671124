$font-bold: GothamBold, Arial, Helvetica, sans-serif;

$font-narrow-book: GothamNarrowBook, Arial, Helvetica, sans-serif;
$font-narrow-bold: GothamNarrowBold, Arial, Helvetica, sans-serif;

$font-normal: GothamBook, Arial, Helvetica, sans-serif;
$font-book: GothamBook, Arial, Helvetica, sans-serif;
$font-book-medium: GothamBookMedium, Arial, Helvetica, sans-serif;

$font-icon: 'ChevronIcons' !important;


.gotham-book {
    font-family: $font-book;
}

.gotham-bold {
    font-family: $font-bold;
}

.gotham-book-medium {
    font-family: $font-book-medium;
}

.gotham-narrow-book {
    font-family: $font-narrow-book;
}

.gotham-narrow-bold {
    font-family: $font-narrow-bold;
}


.h1, h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: unset;
    font-weight: unset;
}

.h2, h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: unset;
    font-weight: unset;
}

.h3, h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: unset;
    font-weight: unset;
}

.h4, h4 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: unset;
    font-weight: unset;
}

.h5, h5 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: unset;
    font-weight: unset;
}

.p1, p {
    font-size: 16px;
    line-height: 24px;
}

.p2 {
    font-size: 14px;
    line-height: 18px;
}

.p3 {
    font-size: 12px;
    line-height: 16px;
}
