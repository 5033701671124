@import "common";


@function cvx-theme-palette($light, $medium, $dark) {
    @return (
        light: $light,
        medium: $medium,
        dark: $dark,
    );
};

@function cvx-theme($primary, $accent, $warning) {
    @return (
        primary-light: map-get($primary, 'light'),
        primary-medium: map-get($primary, 'medium'),
        primary-dark: map-get($primary, 'dark'),

        accent-light: map-get($accent, 'light'),
        accent-medium: map-get($accent, 'medium'),
        accent-dark: map-get($accent, 'dark'),

        warning-light: map-get($warning, 'light'),
        warning-medium: map-get($warning, 'medium'),
        warning-dark: map-get($warning, 'dark'),
    );
};

@mixin cvx-core-theme($theme) {
    .cvx-primary-light-background {
        background-color: map-get($theme, 'primary-light');
    }

    .cvx-primary-medium-background {
        background-color: map-get($theme, 'primary-medium');
    }

    .cvx-primary-dark-background {
        background-color: map-get($theme, 'primary-dark');
    }

    .cvx-primary-light-color {
        color: map-get($theme, 'primary-light');
    }

    .cvx-primary-medium-color {
        color: map-get($theme, 'primary-medium');
    }

    .cvx-primary-dark-color {
        color: map-get($theme, 'primary-dark');
    }


    .cvx-accent-light-background {
        background-color: map-get($theme, 'accent-light');
    }

    .cvx-accent-medium-background {
        background-color: map-get($theme, 'accent-medium');
    }

    .cvx-accent-dark-background {
        background-color: map-get($theme, 'accent-dark');
    }

    .cvx-accent-light-color {
        color: map-get($theme, 'accent-light');
    }

    .cvx-accent-medium-color {
        color: map-get($theme, 'accent-medium');
    }

    .cvx-accent-dark-color {
        color: map-get($theme, 'accent-dark');
    }


    .cvx-warning-light-background {
        background-color: map-get($theme, 'warning-light');
    }

    .cvx-warning-medium-background {
        background-color: map-get($theme, 'warning-medium');
    }

    .cvx-warning-dark-background {
        background-color: map-get($theme, 'warning-dark');
    }

    .cvx-warning-light-color {
        color: map-get($theme, 'warning-light');
    }

    .cvx-warning-medium-color {
        color: map-get($theme, 'warning-medium');
    }

    .cvx-warning-dark-color {
        color: map-get($theme, 'warning-dark');
    }


    .cvx-light-color {
        color: $white;
    }

    .cvx-dark-color {
        color: $black;
    }

    a {
        color: map-get($theme, 'primary-medium');
        text-decoration: none;

        &:hover {
            color: map-get($theme, 'primary-light');
        }
    }


    .modal-header {
        color: $white;
        background-color: map-get($theme, 'primary-dark');
    }

    .text-primary {
        color: map-get($theme, 'primary-medium') !important;
    }


    @include button-theme($theme);
    @include site-navigation-theme($theme);
}
